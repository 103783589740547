import { reactive } from "vue"
import _ from "lodash"
import { callCondensFunction, currentUserHasAccess } from "./utils"

export const collections = reactive({
	users: {},
	orgs: {},
	workspaces: {},
})

let versionSnapshotParams = null
let syncTimeoutId = -1
export const startOrRefreshCollectionsSync = async () => {
	clearTimeout(syncTimeoutId)
	if (!(await currentUserHasAccess())) {
		return
	}
	const syncCollections = async () => {
		const result = await callCondensFunction("internal/getCollections", {
			collections: ["users", "orgs", "workspaces"],
			...versionSnapshotParams,
		})
		versionSnapshotParams = result.versionSnapshotParams
		for (const [collection, items] of Object.entries(result.items)) {
			for (const item of items) {
				if (item.deleteVersion != null && collection !== "orgs") {
					delete collections[collection][item.id]
				} else {
					collections[collection][item.id] = Object.freeze(item)
				}
			}
		}
	}
	syncCollections()
	syncTimeoutId = setTimeout(syncCollections, 3 * 60 * 10000)
}

export const userById = id => {
	if (id.startsWith("anonymous")) {
		return {
			email: id,
			name: id,
		}
	}
	return collections.users[id] ?? { id, email: "", name: "" }
}

export const orgById = id => collections.orgs[id] ?? { id, name: "" }
export const workspaceById = id => collections.workspace[id] ?? { id, name: "" }
