<template>
	<div>
		<div class="key-metrics">
			<div class="entry">
				<div class="header">Organisations last 24h:</div>
				<div class="metric">
					{{ activeOrganisationsToday }}
				</div>
			</div>
			<div class="entry">
				<div class="header">Users last 24h:</div>
				<div class="metric">
					{{ activeUsersToday }}
				</div>
			</div>
			<div
				class="entry clickable"
				:class="{ open: showActiveTrialAccounts }"
				@click="showActiveTrialAccounts = !showActiveTrialAccounts"
			>
				<div class="header">Active Trials:</div>
				<div class="metric">
					{{ activeTrials.length }}
				</div>
			</div>
			<div
				class="entry clickable"
				:class="{ open: showNewSignups }"
				@click="showNewSignups = !showNewSignups"
			>
				<div class="header">New Signups last 7d:</div>
				<div class="metric">
					{{ newAccountsThisWeek.length }}
				</div>
			</div>
		</div>
		<div class="active-trials" v-if="showActiveTrialAccounts">
			<h3>Active Trials:</h3>
			<div class="orgs">
				<div class="header">Name</div>
				<div class="header">Days remaining</div>
				<div class="header">Activated</div>
				<template v-for="org in activeTrials" :key="org.activated">
					<div>{{ org.companyName || "??" }}</div>
					<div>{{ trialDaysRemaining(org) }}</div>
					<div>{{ new Date(org.activated).toLocaleDateString() }}</div>
				</template>
			</div>
		</div>
		<div class="active-trials" v-if="showNewSignups">
			<h3>New signups last 7d:</h3>
			<div class="orgs">
				<div class="header">Name</div>
				<div class="header">Plan</div>
				<div class="header">Activated</div>
				<template v-for="org in newAccountsThisWeek" :key="org.activated">
					<div>
						{{ org.companyName || org.id || "??" }}
					</div>
					<div>{{ org.plan }}</div>
					<div>{{ new Date(org.activated).toLocaleDateString() }}</div>
				</template>
			</div>
		</div>
		<div class="report-container">
			<div>
				<button @click="downloadTrialReport">Download trial report</button>
			</div>
			<div>
				<button @click="downloadUsersReport">Download users report</button>
			</div>
			<div>
				<div v-if="!loadingUsageReport">
					<button @click="loadUsageReport">Load usage report</button>
				</div>
				<div v-if="!loadingUsageReport && usageReport != null" class="usage-report">
					<h3>Report for orgs activated in the last 7 days</h3>
					<b>Orgs</b>: {{ asOrgIdsString(usageReportAsList) }}<br />
					<b>Orgs with real projects</b>:
					{{ asOrgIdsString(usageReportAsList.filter(i => i.realProjects > 0)) }}<br />
					<b>Orgs with real but unfinished projects</b>:
					{{
						asOrgIdsString(
							usageReportAsList.filter(i => i.realProjects === 0 && i.realButUnfinishedProjects > 0)
						)
					}}<br />
				</div>
				<div v-if="loadingUsageReport">Loading usage report...</div>
			</div>
			<div>
				<div v-if="!loadingMediaReport">
					<button @click="loadMediaReport">Load media report</button>
				</div>
				<div v-if="!loadingMediaReport && mediaReport != null" class="media-report">
					<div>
						<h3>Media file durations</h3>
						<div
							v-for="entry of durationsForDisplay(mediaReport.fileDurations, 5)"
							:key="entry.workspaceId"
						>
							{{ entry.workspaceId }}: {{ entry.duration }}h
						</div>
					</div>
					<div>
						<h3>Transcription durations</h3>
						<div
							v-for="entry of durationsForDisplay(mediaReport.transcriptionDurations)"
							:key="entry.workspaceId"
						>
							{{ entry.workspaceId }}: {{ entry.duration }}h
						</div>
					</div>
				</div>
				<div v-if="loadingMediaReport">Loading media report...</div>
			</div>
			<div>
				<button @click="downloadCustomerHealthMetrics">Download customer health metrics</button>
			</div>
			<div><router-link to="urlShortener">URL Shortener</router-link></div>
			<div><router-link to="websiteForms">Website Forms</router-link></div>
		</div>
		<h3>User tools</h3>
		<div class="user-tools">
			<div>
				Invitation link
				<input
					type="text"
					v-model="invitationUrlEmail"
					placeholder="Enter email"
					:disabled="invitationUrlLoading"
				/>
				<button
					:disabled="invitationUrlEmail.length === 0 || invitationUrlLoading"
					@click="getInvitationUrl"
				>
					Copy link into clipboard
				</button>
			</div>
			<div>
				Disable 2FA
				<input
					type="text"
					v-model="disable2faEmail"
					placeholder="Enter email"
					:disabled="deleteUserLoading"
				/>
				<button :disabled="disable2faEmail.length === 0 || disable2faLoading" @click="disable2fa">
					Disable 2FA
				</button>
			</div>

			<div>
				Delete user
				<input
					type="text"
					v-model="deleteUserEmail"
					placeholder="Enter email"
					:disabled="deleteUserLoading"
				/>
				<button :disabled="deleteUserEmail.length === 0 || deleteUserLoading" @click="deleteUser">
					Delete
				</button>
			</div>
		</div>
		<h2>Latest sessions</h2>
		<SessionsTable :groupedSessions="latestGroupedSessions" />
		<p><button @click="sessionsLimit += 20">Show more</button></p>
		<div class="user-tools">
			<div>
				<input
					type="text"
					class="orgs-filter"
					v-model="orgsFilterText"
					:placeholder="`Type to filter ${orgIds.length} orgs`"
				/>
				<div class="orgs-list-container">
					<router-link
						:to="{ name: 'org', params: { orgId } }"
						v-for="orgId of showAllOrgs ? filteredOrgs : filteredOrgs.slice(0, 100)"
						:key="orgId"
					>
						{{ orgId }}
					</router-link>
					<button v-if="!showAllOrgs && filteredOrgs.length > 100" @click="showAllOrgs = true">
						Show all
					</button>
				</div>
			</div>
			<div>
				<input
					type="text"
					class="orgs-filter"
					v-model="usersFilterText"
					:placeholder="`Type to filter ${users.length} users`"
				/>
				<div class="users-list-container">
					<div
						v-for="user of showAllUsers ? filteredUsers : filteredUsers.slice(0, 100)"
						:key="user.id"
					>
						{{ user.email }}, {{ user.name }},
						<router-link :to="{ name: 'org', params: { orgId: user.orgId } }">{{
							user.orgId
						}}</router-link>
						,
						{{ user.frontendRole || user.role }}
						{{ user.invitationPending ? ", invitation_pending" : "" }}
					</div>
					<button v-if="!showAllUsers && filteredUsers.length > 100" @click="showAllUsers = true">
						Show all
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { subDays } from "date-fns"
import SessionsTable from "@/components/SessionsTable.vue"
import {
	splitUpAndGroupSessions,
	callCondensFunction,
	CONDENS_FUNCTIONS_URL,
	downloadFromCloudFunction,
} from "@/utils.js"
import { startOrRefreshCollectionsSync } from "@/collections"
import { collections } from "@/collections"
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/auth"
import _ from "lodash"

export default {
	name: "Dashboard",
	computed: {
		orgs() {
			return Object.values(collections.orgs)
		},
		users() {
			return _.sortBy(Object.values(collections.users), "email")
		},
		orgIds() {
			const ids = this.orgs.map(o => o.id)
			return ids.filter(id => !id.includes("condens")).sort()
		},
		latestGroupedSessions() {
			return this.groupedSessions.slice(0, this.sessionsLimit)
		},
		usageReportWorkspaces() {
			return Object.values(this.usageReport.workspaces)
		},
		workspacesWithRealProjects() {
			return this.filterWorkspace(w => w.realProjects > 0)
		},
		churnedWorkspaces() {
			return this.filterWorkspace(w => w.churned)
		},
		usageReportAsList() {
			if (this.usageReport == null) {
				return []
			}
			return Object.entries(this.usageReport).map(i => ({ orgId: i[0], ...i[1] }))
		},
		activeTrials() {
			return this.orgs
				.filter(
					o => o.deleteVersion == null && o.plan === "trial" && this.trialDaysRemaining(o) > 0
				)
				.sort((a, b) => this.trialDaysRemaining(a) - this.trialDaysRemaining(b))
		},
		newAccountsThisWeek() {
			const dayMiliseconds = 1000 * 3600 * 24
			return this.orgs
				.filter(
					o => o.deleteVersion == null && o.activated > new Date().getTime() - 7 * dayMiliseconds
				)
				.sort((a, b) => b.activated - a.activated)
		},
		filteredOrgs() {
			const filterText = this.orgsFilterText.trim().toLowerCase()
			if (filterText === "") {
				return this.orgIds
			}
			return this.orgIds.filter(o => o.toLowerCase().includes(filterText))
		},
		filteredUsers() {
			const filterText = this.usersFilterText.trim().toLowerCase()
			if (filterText === "") {
				return this.users
			}
			return this.users.filter(
				o => o.name.toLowerCase().includes(filterText) || o.email.toLowerCase().includes(filterText)
			)
		},
	},
	methods: {
		async loadUsageReport() {
			this.loadingUsageReport = true
			this.usageReport = {}
			this.usageReport = await callCondensFunction("internal/usageReport", {})
			this.loadingUsageReport = false
		},
		async loadMediaReport() {
			this.loadingMediaReport = true
			this.mediaReport = {}
			this.mediaReport = await callCondensFunction("internal/mediaUsageReport", {})
			this.loadingMediaReport = false
		},
		filterWorkspace(predicate) {
			return Object.entries(this.usageReport.workspaces)
				.filter(([key, value]) => predicate(value))
				.map(([key, value]) => key)
		},

		trialDaysRemaining(org) {
			if (org.activated == null || org.plan !== "trial") {
				return null
			}
			let td = 15
			if (org.trialDuration) {
				td = org.trialDuration
			}
			const DAY_MS = 24 * 60 * 60 * 1000
			const diffMs = new Date().getTime() - org.activated
			const diffDays = Math.floor(diffMs / DAY_MS)
			return td - diffDays
		},
		startRouterAnalyticsQuery() {
			this.unsubAnalyticsQuery?.()
			this.unsubAnalyticsQuery = firebase
				.firestore()
				.collection("webappAnalytics")
				.where("updated", ">", subDays(new Date(), 1).getTime())
				.onSnapshot(s => {
					const sessions = splitUpAndGroupSessions(
						s.docs.map(d => Object.freeze({ id: d.id, ...d.data() }))
					).filter(i => !i.sessions[0]?.orgId.includes("condens"))
					const activeSessions = sessions.filter(
						s => s.sessions[0].to > new Date().getTime() - 24 * 60 * 60 * 1000
					)
					this.activeOrganisationsToday = _.uniq(
						activeSessions.map(s => s.sessions[0].orgId)
					).length
					this.activeUsersToday = activeSessions.length
					this.groupedSessions = sessions.slice(0, 100).map(s => Object.freeze(s))
				})
		},
		durationsForDisplay(durationByWorkspaceId, maxDuration = 0) {
			const sorted = _.sortBy(
				Object.entries(durationByWorkspaceId).filter(i => i[1] > maxDuration),
				i => -i[1]
			)
			return sorted.map(i => ({ workspaceId: i[0], duration: i[1].toFixed(1) }))
		},
		selectUser(email, userSelectableCb = () => true) {
			email = email.toLowerCase().trim()
			let users = this.users.filter(u => u.email === email)
			if (users.length === 0) {
				window.alert("User with this email doesn't exist")
				return null
			}
			users = users.filter(u => userSelectableCb(u))
			if (users.length > 1) {
				const orgIds = users.map(u => u.orgId)
				const orgId = window.prompt(
					"Email is used in multiple orgs:\n\n" + orgIds.join("\n") + "\n\nPlease enter the org id"
				)
				if (!orgId) {
					return
				}
				if (!orgIds.includes(orgId)) {
					window.alert("Invalid org id selected")
					return
				}
				users = users.filter(u => u.orgId === orgId)
			}
			return users[0]
		},
		async getInvitationUrl() {
			const user = this.selectUser(this.invitationUrlEmail, u => u.invitationPending === true)
			if (user == null) {
				return
			}
			this.invitationUrlLoading = true
			let result = null
			try {
				result = await callCondensFunction(
					"internal/getInvitationUrl",
					{ userId: user.id },
					{ method: "post" }
				)
			} catch (e) {
				if (e.response?.data?.reason === "cant_create_invitation_url_for_user") {
					window.alert("User doesn't have invitation pending")
					return
				}
				console.error(e)
				window.alert("Failed on server, please contact matej")
				return
			} finally {
				this.invitationUrlLoading = false
			}
			this.invitationUrlEmail = ""
			navigator.clipboard.writeText(result.url)
			window.alert("Link copied to clipbaord")
		},
		async tryDeleteUser() {
			const user = this.selectUser(this.deleteUserEmail)
			if (user == null) {
				return
			}
			const orgId = window.prompt(
				"Really delete user " +
					user.email +
					" org id " +
					user.orgId +
					" role " +
					user.role +
					"?\n" +
					"To confirm, type in the org id:"
			)
			if (orgId !== user.orgId) {
				return false
			}
			try {
				await callCondensFunction("internal/deleteUser", { userId: user.id }, { method: "post" })
				alert("User " + user.email + " deleted.")
				startOrRefreshCollectionsSync()
				return true
			} catch (e) {}
			return false
		},
		async deleteUser() {
			this.deleteUserLoading = true
			await this.tryDeleteUser()
			this.deleteUserLoading = false
			this.deleteUserEmail = ""
		},
		async tryDisable2fa() {
			let user = this.users.find(
				u => u.email === this.disable2faEmail && u.authMethod === "password"
			)
			if (user == null) {
				window.alert("User with this email doesn't exist or uses SSO")
				return null
			}
			window.alert(
				"!!! IMPROTANT !!!\nOnly reset 2FA if you have verified that it's the actual person. Just email is not enough (especially for larger accounts). For example, ask someone else in the org to confirm."
			)
			const verification = "i verified user " + user.email
			if (
				window.prompt(
					"Really disable 2FA for user " +
						user.email +
						" org id " +
						user.orgId +
						" role " +
						user.role +
						"?\n!!! ONLY DO THIS IF YOU VERIFIED THE PERSON'S IDENTITY !!!" +
						'To confirm, type "' +
						verification +
						'":'
				) !== verification
			) {
				window.alert("aborted")
				return false
			}
			try {
				await callCondensFunction(
					"internal/disableTotpForUser",
					{ email: user.email },
					{ method: "post" }
				)
				alert("2FA for user " + user.email + " disabled.")
				return true
			} catch (e) {}
			return false
		},
		async disable2fa() {
			this.disable2faLoading = true
			await this.tryDisable2fa()
			this.disable2faLoading = false
			this.disable2faEmail = ""
		},
		downloadTrialReport() {
			const days = window.prompt("How many days into the past?", "30")
			downloadFromCloudFunction("internal/trialStatistics", { days })
		},
		downloadCustomerHealthMetrics() {
			downloadFromCloudFunction("internal/healthMetricsForCustomers")
		},
		downloadUsersReport() {
			downloadFromCloudFunction("internal/usersStatistics")
		},
		asOrgIdsString(items) {
			return items.map(i => i.orgId).join(", ")
		},
	},
	watch: {
		goToOrgId(orgId) {
			if (orgId) {
				this.$router.push({ name: "org", params: { orgId } })
			}
		},
	},
	beforeUnmount() {
		this.unsubAnalyticsQuery?.()
	},
	created() {
		this.startRouterAnalyticsQuery()
	},
	data() {
		return {
			groupedSessions: [],
			activeOrganisationsToday: 0,
			activeUsersToday: 0,
			loadingUsageReport: false,
			usageReport: null,
			loadingMediaReport: false,
			mediaReport: null,
			sessionsLimit: 20,
			goToOrgId: "",
			showActiveTrialAccounts: false,
			showNewSignups: false,
			invitationUrlEmail: "",
			invitationUrlLoading: false,
			deleteUserEmail: "",
			deleteUserLoading: false,
			disable2faEmail: "",
			disable2faLoading: false,
			orgsFilterText: "",
			showAllOrgs: false,
			usersFilterText: "",
			showAllUsers: false,
		}
	},
	components: { SessionsTable },
}
</script>

<style lang="scss" module></style>
<style lang="scss">
.key-metrics {
	display: flex;
	margin: 24px 0;

	.entry {
		margin-right: 36px;
		padding: 6px;
		border-radius: 5px;
		.metric {
			font-size: 36px;
			font-weight: 600;
		}
	}
}
.clickable {
	cursor: pointer;

	&.open {
		background-color: #f5f5f5;
	}

	&:hover {
		background-color: #eeeeee;
	}
}

.user-tools {
	margin: 15px 0;
	display: flex;
	> * {
		margin-right: 15px;
		> * {
			margin-right: 5px;
		}
	}
}

.orgs {
	display: grid;
	grid-template-columns: max-content max-content max-content;
	column-gap: 36px;
	row-gap: 6px;

	margin-bottom: 24px;

	.header {
		font-weight: 600;
	}
}

.report-container {
	display: flex;
	> * {
		flex-grow: 1;
		flex-shrink: 1;
		width: 50%;
	}
}

.media-report {
	display: flex;
	> * {
		flex-grow: 1;
	}
}

.usage-report {
}

.orgs-list-container {
	height: 240px;
	overflow: auto;
	width: 300px;
	> a {
		display: block;
	}
}
.users-list-container {
	height: 240px;
	overflow: auto;
	width: 600px;
}
</style>
